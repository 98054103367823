import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Accordian, AccordianItem } from "../components/Accordian";
import { CarouselComponent, CarouselComponentSlide } from '../components/carousel';
import { NewsblockContainer, Newsblock } from '../components/Newsblock';
import "../../scss/main.scss";

export default function Blog() {
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Blog</title>
			</Helmet>
			<div className="container">
				<Header />
				<main>
					<div className="blog-landing">
						<div className="blog-landing-content">
							<div className="drop-down">
								<h1>Stories and news from the Edyn team and clients</h1>
								<Accordian>
									<AccordianItem
										renderTitle={() => (
											<p className="secondary">Information on live-in care</p>
										)}
									>
										<p>
											Lorem ipsum dolor sit amet consectetur adipisicing elit.
											Voluptates quo ipsa veniam dolore neque odit earum natus
											obcaecati molestias suscipit.
										</p>
									</AccordianItem>
									<AccordianItem
										renderTitle={() => (
											<p className="secondary">About our services</p>
										)}
									>
										<p>
											Lorem ipsum dolor sit amet consectetur adipisicing elit.
											Voluptates quo ipsa veniam dolore neque odit earum natus
											obcaecati molestias suscipit.
										</p>
									</AccordianItem>
									<AccordianItem
										renderTitle={() => (
											<p className="secondary">Carer of the month</p>
										)}
									>
										<p>
											Lorem ipsum dolor sit amet consectetur adipisicing elit.
											Voluptates quo ipsa veniam dolore neque odit earum natus
											obcaecati molestias suscipit.
										</p>
									</AccordianItem>
									<AccordianItem
										renderTitle={() => (
											<p className="secondary">Latest care news and stories</p>
										)}
									>
										<p>
											Lorem ipsum dolor sit amet consectetur adipisicing elit.
											Voluptates quo ipsa veniam dolore neque odit earum natus
											obcaecati molestias suscipit.
										</p>
									</AccordianItem>
								</Accordian>
							</div>
						</div>
						<StaticImage placeholder="blurred"
							className="blog-landing-right"
							src="../../static/assets/marketing-site/images/blog-image-01.svg"
							loading="eager"
							placeholder="tracedSVG"
						/>
					</div>
					<hr className="mobile-only" />
					<section className="info-block-pricing-page">
						<div className="paying-for-care">
							<div className="info-block-section left">
								<h1>An overview of Live-in care</h1>
								<div className="info-block-content">
									<div className="infoblock read-more yellow">
										<h3>Transitioning to live-in care</h3>
										<Link className="info-read" to="">
											Read more
												<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>Live-in care for couples</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>Preparing for care</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
					<hr className="mobile-only" />

					<section className="info-block-pricing-page">
						<div className="paying-for-care">
							<div className="info-block-section left">
								<h1>Our services</h1>
								<div className="info-block-content">
									<div className="infoblock read-more yellow">
										<h3>What happens in my care consultation?</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>What happens in my care assessment?</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>What is a fully managed agency?</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
					<hr className="mobile-only" />

					<NewsblockContainer title="Carer of the month" className="newsblock-desktop">
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="Meet Florence, August's Carer of the month"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/carer-img-01.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="Meet Mandrani, July’s Carer of the month"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/carer-img-02.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="Meet Tamas, June’s Carer of the month"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/carer-img-03.svg" />
						</Newsblock>
					</NewsblockContainer>

					<NewsblockContainer className="newsblock-mobile" title="Carer of the month">
						<CarouselComponent>
							<CarouselComponentSlide>
								<Newsblock
									color="yellow"
									date="'{DD MM}'"
									title="Meet Florence, August's Carer of the month"
									description="I represent Edyn Care by making a remarkable difference
											in the journey of caring and supporting for our lovely
											clients."
									link="#">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/carer-img-01.svg" />
								</Newsblock>
							</CarouselComponentSlide>
							<CarouselComponentSlide>
								<Newsblock
									color="yellow"
									date="'{DD MM}'"
									title="Meet Mandrani, July’s Carer of the month"
									description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
									link="#">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/carer-img-02.svg" />
								</Newsblock>
							</CarouselComponentSlide>
							<CarouselComponentSlide>
								<Newsblock
									color="yellow"
									date="'{DD MM}'"
									title="Meet Tamas, June’s Carer of the month"
									description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
									link="#">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/carer-img-03.svg" />
								</Newsblock>
							</CarouselComponentSlide>
						</CarouselComponent>
					</NewsblockContainer>
											
					<NewsblockContainer title="Latest care news and stories" className="newsblock-desktop newsblock-latest top">
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the qualities of a good carer"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="How do you know when you need care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-4.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the options for elderly care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-3.svg" />
						</Newsblock>
					</NewsblockContainer>
					<NewsblockContainer className="newsblock-desktop newsblock-latest bottom">
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="Why choose live-in care over a care home?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-2.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="10 tips to look after yourself as a carer"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-1.svg" />
						</Newsblock>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What is the role of live-in carers?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
						</Newsblock>
					</NewsblockContainer>


					<NewsblockContainer title="Latest care news and stories" className="newsblock-mobile">
					<CarouselComponent>
						<CarouselComponentSlide>
							<Newsblock
								color="yellow"
								date="'{DD MM}'"
								title="What are the qualities of a good carer"
								description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
								link="#">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
							</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
							<Newsblock
								color="yellow"
								date="'{DD MM}'"
								title="How do you know when you need care?"
								description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
								link="#">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-4.svg" />
							</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What are the options for elderly care?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-3.svg" />
						</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
							<Newsblock
								color="yellow"
								date="'{DD MM}'"
								title="Why choose live-in care over a care home?"
								description="I represent Edyn Care by making a remarkable difference
										in the journey of caring and supporting for our lovely
										clients."
								link="#">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-2.svg" />
							</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="10 tips to look after yourself as a carer"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-1.svg" />
						</Newsblock>
						</CarouselComponentSlide>
						<CarouselComponentSlide>
						<Newsblock
							color="yellow"
							date="'{DD MM}'"
							title="What is the role of live-in carers?"
							description="I represent Edyn Care by making a remarkable difference
									in the journey of caring and supporting for our lovely
									clients."
							link="#">
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/blog-article-5.svg" />
						</Newsblock>
						</CarouselComponentSlide>
						</CarouselComponent>
					</NewsblockContainer>


				</main>
			</div>
			<Footer />
		</>
	);
}
