import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "./newsblock.scss";

export const NewsblockContainer = (props) => {
    return(
        <section className={`carer-section carousel ${props.className}`}>
        <div className="carer-section-blocks ">
            <h1>{props.title}</h1>
                <div className="info-block-section left">
                    {props.children}
                </div>
            </div>
            <Link to="/cta" className="button-link">
                <button className="btn-primary load-more-btn">Load more</button>
            </Link>
        </section>
    )
}

export const Newsblock = (props) => {
    return (
        <div className="info-block-content slide active">
            <div className={`infoblock-carer sec1 fade read-more ${props.color}  `}>
                {props.children}
                <div className="info-block-text">
                    <p className="secondary info-block-text_date">{props.date}</p>
                    <h3>{props.title}</h3>
                    <p className="primary info-block-text_paragraph">
                        {props.description}
                    </p>
                    <Link className="info-read" to={props.link}>
                        Read more
                        <StaticImage placeholder="blurred"
                            className="icon-default"
                            src="../../static/assets/marketing-site/images/arrow-right.svg"
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}