import * as React from "react";

import "./carousel.scss";

export const CarouselComponent = (props) => {
    const [activeItem, setActiveItem] = React.useState(0);
    React.useEffect(()=>{
        // Automatic Carousel
        const slidesCount = props.children.length;
        if(activeItem === slidesCount){
            setActiveItem(0)
        }
        const id = setTimeout(()=> {
            setActiveItem(activeItem + 1);
        }, 9000)

        return () => {
            clearTimeout(id);
        };
    }, [activeItem])



  return (
    <section className={props.className + " carousel"}>
      <div className="carousel-container">
        <h1>{props.title}</h1>
        {props.children.map((slide, index)=>{
              return(
                  <div key={index} className={activeItem === index ? 'active-slide' : 'inactive-slide'}>
                      {slide}
                  </div>
              )
          })} 
        <div className="carousel-dots">
          {props.children.map((slide, index) => (
            <span
              style={
                activeItem === index
                  ? {
                      backgroundColor: "#1c4042",
                    }
                  : {}
              }
              onClick={() => setActiveItem(index)}
              onKeyDown={() => setActiveItem(index)}
              aria-hidden="true"
              key={index}
              className="dot"
            ></span>
          ))}
        </div>
      </div>
    </section>
  );
};

export const CarouselComponentSlide = (props) => {

  return (

    <div className="slide">
      <React.Fragment>
      
        <div className="slide-image">
          {props.children}
        </div>
        
        <div className="slide-description">
            <h3>{props.slideName}</h3>
            <br/>
            <p className="primary">{props.slideText}</p>
        </div>
        </React.Fragment>
    </div>
  );
};