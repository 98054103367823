import React, { useCallback, useEffect, useState, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const Accordian = ({ children }) => <ul>{children}</ul>;

// N.B - original source for expand / collapse from here - https://css-tricks.com/using-css-transitions-auto-dimensions/#technique-3-javascript

const transitionStyle = "height 0.3s ease-in";

export const AccordianItem = ({ children, renderTitle }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [contendDataCollapsed, setContendDataCollapsed] = useState(true);
	const collapseableContentRef = useRef();
	const isFirstRender = useRef(true);
	const isTransitionInProgress = useRef(false);
	const [contentHeight, setContentHeight] = useState();
	const [contentTransition, setContentTransition] = useState(transitionStyle);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		const contentElement = collapseableContentRef.current;

		if (isOpen) {
			isTransitionInProgress.current = true;

			// have the element transition to the height of its inner content
			setContentHeight(`${contentElement.scrollHeight}px`);

			const onTransitionEnd = (e) => {
				contentElement.removeEventListener("transitionend", onTransitionEnd);

				setContentHeight("auto");
				isTransitionInProgress.current = false;
			};

			// when the next css transition finishes (which should be the one we just triggered)
			contentElement.addEventListener("transitionend", onTransitionEnd);

			setContendDataCollapsed(false);
		} else {
			isTransitionInProgress.current = true;

			// get the height of the element's inner content, regardless of its actual size
			const elementHeight = contentElement.scrollHeight;

			// temporarily disable all css transitions
			// const elementTransition = contentElement.style.transition;
			setContentTransition("");
			// contentElement.style.transition = "";

			// on the next frame (as soon as the previous style change has taken effect),
			// explicitly set the element's height to its current pixel height, so we
			// aren't transitioning out of 'auto'
			requestAnimationFrame(function () {
				setContentHeight(`${elementHeight}px`);
				setContentTransition(transitionStyle);

				// on the next frame (as soon as the previous style change has taken effect),
				// have the element transition to height: 0
				requestAnimationFrame(function () {
					setContentHeight("0px");
					isTransitionInProgress.current = false;
				});
			});
		}
	}, [isOpen]);

	const toggleIsOpen = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onClick = useCallback(
		(e) => {
			e.preventDefault();

			if (!isTransitionInProgress.current) toggleIsOpen();
		},
		[toggleIsOpen]
	);

	return (
		<li className="accordion" onClick={onClick}>
			<Link
				to="#"
				className={`accordion-link ${isOpen ? "active-accordion-link" : ""}`}
			>
				<StaticImage placeholder="blurred"
					src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
					alt=""
				/>
				{renderTitle({ isOpen, toggleIsOpen })}
			</Link>
			<div
				ref={collapseableContentRef}
				style={{
					height: contentHeight,
					transition: contentTransition,
				}}
				className="accordion-items-container"
				data-collapsed={contendDataCollapsed ? "true" : "false"}
			>
				{children}
			</div>
		</li>
	);
};
